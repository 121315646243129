<!-- 需求-需求发布未承接 -->
<template>
    <div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
        <div class="title">需求发布及承接</div>
        <div class="box">
            <div class="customer">
                <div class="item-row" v-for="item in list" :key="item">
                    <div class="name">
                        <div class="sign" :style="{ backgroundColor: item.itemStyle.color }"></div>
                        <div>{{ item.text }}客户工位需求：</div>
                    </div>
                    <div class="value" @click="goUrl(item.url, item.value, item.id, '1')">{{ item.value }}</div>
                </div>
            </div>
            <el-divider class="divider " direction="vertical" />
            <div class="number">
                <div class="statistics">
                    <div class="total drifts" @click="goUrl(url, publish_no_assign, publish_no_assign_ids, '0')">
                        <div>{{ publish_no_assign }}</div>
                        <div>需求发布未承接总数</div>
                    </div>
                    <div class="total moon"
                        @click="goUrl(url, publish_no_assign_month, publish_no_assign_month_ids, '0')">
                        <div>{{ publish_no_assign_month }}</div>
                        <div>本月新增</div>
                    </div>
                </div>
                <div class="statistics undertake">
                    <div class="total moon drifts"
                        @click="goUrl(url, publish_prepare_assign, publish_prepare_assign_ids, '0')">
                        <div>{{ publish_prepare_assign }}</div>
                        <div>初步方案</div>
                    </div>
                    <div class="total moon" @click="goUrl(url, publish_assign, publish_assign_ids, '0')">
                        <div>{{ publish_assign }}</div>
                        <div>正式方案</div>
                    </div>
                </div>
                <el-divider class="segment" />
                <div :style="{ width: '100%', height: '200px', overflow: 'hidden' }" ref="sceneCake"></div>
                <el-divider class="segment" />
                <div class="buts" @click="goUrl(url, publish_no_assign, publish_no_assign_ids, '0')">
                    进入接单
                </div>
            </div>
        </div>

    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { demand_stage_analysis_new } from "@/api/ChartsDataRequest";

export default {
    name: "demandUnaccepted",
    props: [],
    emits: ["closeChart"],
    data() {
        return {
            loading: false,
            url: '/customerManage/reviewedWorkstations?jump_type=',
            publish_no_assign: '',//需求发布未承接总数
            publish_no_assign_ids: '1',
            publish_no_assign_month: '',//本月新增
            publish_no_assign_month_ids: '11',
            publish_assign: '',//正式方案
            publish_assign_ids: '2',
            publish_prepare_assign: '',//初步方案
            publish_prepare_assign_ids: '10',
            list: [
                {
                    text: '特级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#A9A1D9' }
                },
                {
                    text: 'AAA级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#86B6FF' }
                },
                {
                    text: 'AA级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#1881E1' }
                },
                {
                    text: 'A级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#B08654' }
                },
                {
                    text: 'B级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#67C23A' }
                },
                {
                    text: 'C级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#047770' }
                },
                {
                    text: 'D级',
                    url: '/customerManage/reviewedWorkstations?jump_type=12',
                    value: 0,
                    id: '',
                    itemStyle: { color: '#FE7E24' }
                }
            ]
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        // 图表创建
        createChart() {
            this.ChartDom = this.$refs.sceneCake;
            this.MyChart = echarts.init(this.ChartDom, null);

            demand_stage_analysis_new().then((res) => {

                if (res.data.code == 200) {
                    this.publish_no_assign = res.data.data.publish_no_assign || 0
                    this.publish_no_assign_month = res.data.data.publish_no_assign_month || 0
                    this.publish_assign = res.data.data.publish_assign || 0
                    this.publish_prepare_assign = res.data.data.publish_prepare_assign || 0



                    // 计算总数  res.data.data.customer_level
                    const totalCount = res.data.data.customer_level.reduce((sum, level) => {
                        if (level.level_text !== '未设等级') {
                            return sum + level.count
                        }
                        return sum
                    }, 0);

                    // res.data.data.customer_level
                    res.data.data.customer_level.forEach(level => {
                        const matchedItem = this.list.find(item => item.text === level.level_text);
                        if (matchedItem) {
                            matchedItem.value = level.count // 将 count 赋值给 value
                            matchedItem.id = level.customer_level


                            // 计算百分比
                            matchedItem.perCent = totalCount > 0 ? (level.count / totalCount * 100).toFixed(2) + '%' : '0%';
                            // matchedItem.perCent = totalCount > 0 ? Math.round((Number(level.count) / Number(totalCount)) * 100) + '%' : '0%'
                        }
                    });


                    // 赋值给饼图,并去除0
                    const data = this.list.filter(item => item.value !== 0);
                    this.MyChart.setOption({
                        title: {
                            text: '',
                            left: 'center',

                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: function (params) {
                                return `
                                        ${params.data.text}客户工位需求<br/>
                                        值: ${params.value}<br/>
                                        占比: ${params.data.perCent}`;
                            },
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: '90%',
                                data,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                },
                                label: {
                                    show: false, // 显示标签
                                    position: 'inside', // 在块内部显示
                                    fontSize: 14 // 标签字体大小
                                }
                            }
                        ]
                    });
                    this.loading = false;
                }
            });
            setTimeout(() => {
                this.$nextTick(() => {
                    this.MyChart.resize();
                });
            }, 0);
        },
        // 跳转
        goUrl(row, value, id, type) {
            let url
            if (type == '1') {
                url = row + '&customer_level=' + id
            } else {
                url = row + id
            }

            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(url)
        },

        sizechange() {
            this.MyChart.resize();
        },
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.title {
    height: 68px;
    line-height: 68px;
    font-size: 20px;
    text-align: center;
    color: #fff;
}

.box {
    display: flex;

    // 分割线
    .divider {
        height: 480px;
        margin: 0px 0 0 0;
        border-color: #3C5B58;
    }

    .customer {
        flex: 1;
        padding: 0 10px;

        .item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            background-color: #005F59;
            border-radius: 8px;
            height: 60px;
            line-height: 60px;
            margin-bottom: 10px;
            color: #fff;

            .name {
                // padding-left: 8px;
                display: flex;
                align-items: center;

                .sign {
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    border-radius: 2px;
                }
            }

            .value {
                color: #F9B707;
                font-size: 25px;
                padding-right: 8px;
                cursor: pointer;
            }
        }
    }

    .number {
        flex: 1;

        .statistics {
            display: flex;
            padding: 0 10px;

            .total {
                width: 50%;
                height: 90px;
                border-radius: 8px;
                background-color: #008980;
                color: #F9B707;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 12px;
                cursor: pointer;

                div:first-child {
                    font-size: 30px;
                }

            }

            .drifts {
                margin-right: 10px;
            }

            .moon {
                background-color: #008980;

                div:first-child {
                    color: #F9B707;
                }
            }
        }

        .undertake {
            margin-top: 10px;
        }

        .segment {
            width: 94%;
            margin: 10px auto 10px;
            border-color: #3C5B58;
        }


        .buts {
            height: 50px;
            line-height: 50px;
            color: #002925;
            margin: auto 6px;
            background-color: #F9B707;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}
</style>
